<template>
  <div class="container">
    <div class="news">
      <router-link v-for="newsCard in 10" :key="newsCard.href" to="#" class="news-card">
        <div class="title">
          DreamEaters обыграла NAVI и вышла в финал верхней сетки Matreshka Russian Cup 2020
          DreamEaters обыграла NAVI и вышла в финал верхней сетки Matreshka Russian Cup 2020
          DreamEaters обыграла NAVI и вышла в финал верхней сетки Matreshka Russian Cup 2020
          DreamEaters обыграла NAVI и вышла в финал верхней сетки Matreshka Russian Cup 2020
        </div>
        <div class="time">Сегодня</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "tournamentNews"
}
</script>

<style scoped>

</style>